<template>
  <v-row>
    <v-col>
      <v-card>
        <v-toolbar flat>
          <v-icon>mdi-credit-card-outline</v-icon>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-toolbar-title class="d-none d-sm-flex align-center">
            <span>{{ camelToSentence($options.name) }}</span>
          </v-toolbar-title>
          <v-spacer />
          <SubscriptionListFilter :filter="filter" />
          <v-btn
            color="primary"
            dark
            @click="openForm(null)"
          >
            Add
          </v-btn>
        </v-toolbar>

        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="meta.total"
          :loading="loading"
          disable-sort
          mobile-breakpoint="0"
          :footer-props="footerProps"
        >
          <template v-slot:item.ends_at="{ item }">
            <span>{{ item.ends_at | formatDate }}</span>
          </template>
          <!-- actions -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  class="mr-2"
                  v-on="on"
                  @click="openForm(item)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  color="error"
                  class="mr-2"
                  v-on="on"
                  @click="deleteItem(item)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>

      <SubscriptionForm
        ref="formModal"
        :item="item"
        @reload-data="getItem()"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { parseISO, format } from 'date-fns';
import DatalistMixin from '../../mixins/DatalistMixin';
import SubscriptionForm from './SubscriptionForm.vue';
import SubscriptionListFilter from './SubscriptionListFilter.vue';

export default {
  name: 'SubscriptionList',
  components: { SubscriptionForm, SubscriptionListFilter },
  mixins: [DatalistMixin],
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
          class: 'min-width-10',
        },
        {
          text: 'Organization',
          value: 'organization.name',
        },
        {
          text: 'Plan',
          value: 'plan.name',
        },
        {
          text: 'Ends at',
          value: 'ends_at',
          class: 'min-width-7',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '120px',
        },
      ],
      filter: {
        page: 1,
        plan_id: null,
        organization_id: null,
        ends_before: null,
        ends_after: null,
      },
    };
  },

  created() {
    // fetch vuex store
    this.getPlans();
    this.getOrganizations();
  },

  methods: {
    ...mapActions(['getOrganizations', 'getPlans']),

    getData() {
      return this.$api.getSubscriptions(this.filter);
    },

    deleteData(subsId) {
      return this.$api.deleteSubscription(subsId);
    },
  },
};
</script>
