<template>
  <v-menu
    v-model="filterMenu"
    :close-on-content-click="false"
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        class="mx-3"
        icon
        large
        title="Filter Parameter"
        v-on="on"
      >
        <v-badge
          color="red"
          overlap
          dot
          :value="isFiltered"
        >
          <v-icon>mdi-filter</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-card width="500px">
      <v-card-title>Filter by</v-card-title>
      <v-card-text class="mb-0 pb-0">
        <v-autocomplete
          v-if="user.is_superadmin"
          v-model="filter.organization_id"
          :items="organizations"
          clearable
          item-text="name"
          item-value="id"
          label="Organization"
          prepend-icon="mdi-domain"
          @click:clear="setPropertyToNull(filter,'organization_id')"
        />
        <v-autocomplete
          v-model="filter.plan_id"
          :items="plans"
          clearable
          item-text="name"
          item-value="id"
          prepend-icon="mdi-cash"
          label="Plan"
          @click:clear="setPropertyToNull(filter,'plan_id')"
        />
        <v-menu
          ref="menu"
          v-model="filterDateBeforeMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="filter.ends_before? $options.filters.formatDate(filter.ends_before) : null"
              label="Ends before"
              readonly
              clearable
              prepend-icon="mdi-calendar-arrow-left"
              v-on="on"
              @click:clear="setPropertyToNull(filter,'ends_before')"
            />
          </template>
          <v-date-picker
            v-model="filter.ends_before"
            no-title
            scrollable
            color="primary"
            @input="filterDateBeforeMenu = false"
          />
        </v-menu>
        <v-menu
          ref="menu"
          v-model="filterDateAfterMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="filter.ends_after? $options.filters.formatDate(filter.ends_after) : null"
              label="Ends after"
              readonly
              clearable
              prepend-icon="mdi-calendar-arrow-right"
              v-on="on"
              @click:clear="setPropertyToNull(filter,'ends_after')"
            />
          </template>
          <v-date-picker
            v-model="filter.ends_after"
            no-title
            scrollable
            color="primary"
            @input="filterDateAfterMenu = false"
          />
        </v-menu>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="filterMenu = false"
        >
          Close
        </v-btn>
        <v-btn
          color="secondary"
          :to="routeGenerator({reset:true})"
          @click="filterMenu=false"
        >
          Reset
        </v-btn>
        <v-btn
          color="primary"
          :to="routeGenerator()"
          @click="filterMenu=false"
        >
          Apply
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import ListFilterMixin from '../../mixins/ListFilterMixin';

export default {
  name: 'SubscriptionListFilter',
  mixins: [ListFilterMixin],
  data() {
    return {
      filterDateBeforeMenu: false,
      filterDateAfterMenu: false,
    };
  },
};
</script>
