<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    persistent
    scrollable
  >
    <v-card>
      <v-card-title>
        <span>{{ formTitle }}</span>
        <v-spacer />
        <v-icon @click="closeDialog">
          mdi-close
        </v-icon>
      </v-card-title>

      <ErrorAlert />

      <v-card-text class="pt-5 pb-0">
        <v-form
          ref="form"
          v-model="formValidity"
        >
          <v-text-field
            v-model="form.name"
            :rules="nameRules"
            :disabled="loading"
            :counter="30"
            outlined
            dense
            label="Name"
            @keypress.enter="saveItem"
          />
          <v-autocomplete
            v-model="form.plan_id"
            :rules="planRules"
            :items="plans"
            :disabled="loading"
            auto-select-first
            outlined
            dense
            item-text="name"
            item-value="id"
            label="Plan"
            :search-input.sync="searchInput.name"
          />
          <v-autocomplete
            v-model="form.organization_id"
            :rules="organizationRules"
            :items="organizations"
            :disabled="loading"
            auto-select-first
            outlined
            dense
            item-text="name"
            item-value="id"
            label="Organization"
            :search-input.sync="searchInput.organization"
          />
          <v-menu
            ref="menu"
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :value="form.ends_at? $options.filters.formatDate(form.ends_at) : null"
                label="Ends at"
                :disabled="loading"
                readonly
                outlined
                clearable
                dense
                v-on="on"
                @click:clear="form.ends_at =null"
              />
            </template>
            <v-date-picker
              v-model="form.ends_at"
              no-title
              scrollable
              color="primary"
              @input="dateMenu = false"
            />
          </v-menu>
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn
          text
          :disabled="loading"
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          class="primary"
          :loading="loading"
          @click="saveItem"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import FormMixin from '../../mixins/FormMixin';

export default {
  name: 'SubscriptionForm',
  mixins: [FormMixin],

  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      form: {
        name: '',
        organization_id: null,
        plan_id: null,
        ends_at: null,
      },
      defaultForm: {
        name: '',
        organization_id: null,
        plan_id: null,
        ends_at: null,
      },
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => v.length <= 30 || 'Name must be less than 30 characters',
      ],
      planRules: [
        (v) => !!v || 'Plan is required',
      ],
      organizationRules: [
        (v) => !!v || 'Organization is required',
      ],
      searchInput: {
        name: null,
        organization: null,
      },
      dateMenu: false,
    };
  },

  computed: {
    ...mapGetters(['organizations', 'plans']),
  },

  watch: {
    dialog(bool) {
      if (bool) {
        this.$store.dispatch('getOrganizations');
        if (this.form.ends_at) {
          // pick date only from full ISO string
          this.form.ends_at = this.form.ends_at.substr(0, 10);
        }
        this.clearSearchInput();
      }
    },
  },

  methods: {
    addData() {
      return this.$api.addSubscription(this.formData);
    },

    updateData() {
      return this.$api.updateSubscription({
        id: this.form.id,
        ...this.formData,
      });
    },

    clearSearchInput() {
      this.searchInput.name = null;
      this.searchInput.organization = null;
    },
  },
};
</script>
